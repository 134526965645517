import React from 'react'
import styled from 'styled-components'
import Icon1 from "../../assets/con-mobile-hero-insurance.png";
import Icon2 from "../../assets/con-mobile-hero-copay.png";
import Icon3 from "../../assets/con-mobile-hero-referral.png";
import PageDivider from '../PageDivider';

const ComponentWrapper = styled.div`
    margin: 20px 0 10px 0;
`;

const Title = styled.h2`
    color: var(--dark-blue);
    font-weight: 400;
    font-size: 18px;

    @media(max-width: 415px) {
        font-size: 14px;
        font-weight: 600;
    }
`;

const ProcessContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    @media(max-width: 415px) {
        flex-wrap: wrap;
        flex-direction: column;
    }
`;

const ProcessItem = styled.div`
    @media(max-width: 415px) {
        margin-bottom: 1.5rem;
    }
`;

const ProcessIconOne = styled.div`
    width: 65px;
    height: 60px;
    background-image: url(${Icon1});
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 10px;

    @media(max-width: 415px) {
        width: 70px;
        height: 56px;
    }
`;

const ProcessIconTwo = styled.div`
    width: 65px;
    height: 60px;
    background-image: url(${Icon2});
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 10px;

    @media(max-width: 415px) {
        width: 50px;
        height: 56px;
    }
`;

const ProcessIconThree = styled.div`
    width: 83px;
    height: 60px;
    background-image: url(${Icon3});
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 auto 10px;

    @media(max-width: 415px) {
        width: 90px;
        height: 56px;
    }
`;

const ProcessDetail = styled.p`
    font-size: 14px;
    text-align: center;
    width: 250px;
    margin: 0 auto;
    color: var(--dark-blue);

    @media(max-width: 415px) {
        max-width: 200px;
        font-size: 12px;
        line-height: 14px;
    }

    @media(max-width: 775px) {
        padding: 5px;
        width: 170px;
    }
`;

const SupportProcess = () => (
    <ComponentWrapper>
        <Title>
            The Organon Access Program may be able to help answer questions about: 
        </Title>
        <ProcessContainer>
            <ProcessItem>
                <ProcessIconOne/>
                <ProcessDetail>
                    Insurance coverage
                </ProcessDetail>
            </ProcessItem>
            <ProcessItem>
                <ProcessIconTwo/>
                <ProcessDetail>
                    Co-pay assistance for eligible patients
                </ProcessDetail>
            </ProcessItem>
            <ProcessItem>
                <ProcessIconThree/>
                <ProcessDetail>
                    Referral to the Organon Patient Assistance Program for eligibility determination (provided through the Organon Patient Assistance Program Inc.) 
                </ProcessDetail>
            </ProcessItem>
        </ProcessContainer>
        <PageDivider/>
    </ComponentWrapper>
)

export default SupportProcess;