import React from "react"
import styled from "styled-components"

import WhiteArrow from '../../assets/white-arrow.png';

const SupportCallout = () => (
    <CalloutWrapper>
        <CalloutContainer>
            <CalloutContact>
                Contact The Organon Access Program
                <br/>
                <strong>Mon-Fri 8 <sub>AM</sub> – 8 <sub>PM</sub> ET</strong>
            </CalloutContact>
            
            <CalloutHomeLink><a href="/hcc/">
                <CalloutHome>
                    <span>Choose the product you are interested in to display<br/>the appropriate phone number and/or to enroll</span>
                </CalloutHome>
            </a></CalloutHomeLink>
        </CalloutContainer>
    </CalloutWrapper>
)

const CalloutWrapper = styled.div`
    width: calc(100% + 50px);
    font-family: Montserrat;
    margin: 6px -25px;
`;

const CalloutContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px;

    @media (max-width: 415px) {
        flex-direction: column;
        margin-left: 0;
        margin-right: 0;
    }
`

const CalloutContact = styled.div`
    width: 50%;
    height: 45px;
    background: var(--ice-blue);
    color: var(--dark-blue);
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    padding: 5px 10px;
    margin-right: 5px;

    sub {
        bottom: 0;
    }

    @media (max-width: 415px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 5px;
        margin-right: 0;
    }
`;

const CalloutHomeLink = styled.div`
    width: 50%;

    @media (max-width: 415px) {
        width: 100%;
    }
`

const CalloutHome = styled.div`
    height: 45px;
    background: var(--dark-blue);
    color: var(--white);
    font-size: 14px;
    line-height: 16px;
    padding: 5px 10px;
    font-style: italic;
    position: relative;

    a {
        color: var(--white);
    }

    @media (max-width: 415px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        font-size: 11px;
        line-height: 14px;
        padding: 5px 15px;
    }

    @media (max-width: 775px) {
        font-size: 11px;
    }

    &:after {
        content: '';
        background-image: url(${WhiteArrow});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 27px;
        position: absolute;
        top: 9px;
        left: 93%;
        z-index: 1;

        @media (max-width: 415px) {
            height: 24px;
            left: 92%;
        }

        @media (max-width: 775px) {
            left: 91%;
        }
    }

    span:after {
        content: '';
        background-color: var(--pink);
        position: absolute;
        width: 50px;
        height: 45px;
        top: 0;
        right: 0;
    }
`;

export default SupportCallout