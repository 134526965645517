import React from "react"
import styled from "styled-components"

import WhiteArrow from '../../assets/white-arrow.png';

const EnrollCta = () => (
    <EnrollCtaWrapper>
        <EnrollCtaContainer>
            <h2>Choose the product you are interested in to display the appropriate phone number and/or to enroll</h2>

            <Button>
                <a href="/hcc/">LEARN MORE</a>
            </Button>
        </EnrollCtaContainer>
    </EnrollCtaWrapper>
)

const EnrollCtaWrapper = styled.div`
    width: calc(100% + 50px);
    margin: 1rem -25px;;
`

const EnrollCtaContainer = styled.div`
    margin: 6px;
    background-color: var(--dark-blue);
    padding: 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 415px) {
        flex-direction: column;
        margin-right: 0;
    }

    h2 {
        width: 55%;
        color: var(--white) !important;
        font-weight: bold;
        margin-bottom: 0;

        @media (max-width: 415px) {
            width: 100%;
        }
    }
`

const Button = styled.div`
    font-family: Arial;
    background: var(--pink);
    color: var(--white);
    padding: 5px 45px 5px 12px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    box-shadow: 3px 3px 3px rgb(0 0 0 / 30%);
    cursor: pointer;
    margin-left: 1rem;
    position: relative;

    @media (max-width: 415px) {
        margin-top: 9px;
    }

    a {
        color: var(--white);

        &:after {
            content: '';
            background-image: url(${WhiteArrow});
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 22px;
            position: absolute;
            top: 7px;
            left: 88%;
            z-index: 1;

            @media (max-width: 415px) {
                height: 20px;
                left: 89%;
            }
        }
    }

    :after {
        content: '';
        background-color: var(--light-pink);
        position: absolute;
        width: 36px;
        margin-left: 12px;
        height: 36px;
        margin-top: -5px;
        border-radius: 4px;

        @media (max-width: 415px) {
            height: 33px;
        }
    }
`

export default EnrollCta