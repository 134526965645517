import React from "react"
import { Container } from "../../components/layout/layoutComponents"
import Layout from "../../components/layout"
import HccSupportCallout from '../../components/HccSupportCallout'
import HccListCallout from '../../components/HccListCallout'
import HccEnrollCta from "../../components/HccEnrollCta"
import HccBodyContent from '../../components/HccBodyContent'
import HccSupportProcess from '../../components/HccSupportProcess'
import HccHeader from "../../components/HccHeader"
import HccFooter from "../../components/HccFooter"
import { ComponentWrapper } from "../../components/layout/ComponentWrapper"

const Index = () => (
  <>
  <HccHeader />
  <ComponentWrapper>
  <Layout>
    <Container>
      <HccSupportCallout/>
      <HccSupportProcess/>
      <HccBodyContent/>
      <HccListCallout/>
      <HccEnrollCta/>
    </Container>
  </Layout>
  </ComponentWrapper>
  <HccFooter/>
  </>
)

export default Index

