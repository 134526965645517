import React from "react"
import styled from "styled-components"
import List1 from "../../assets/get-started-bullet-1.png";
import List2 from "../../assets/get-started-bullet-2.png";
import List3 from "../../assets/get-started-bullet-3.png";
import List4 from "../../assets/get-started-bullet-4.png";

const ListCallout = () => (
    <ListWrapper>
        <h2>Getting Started Is Simple</h2>
        <ListContainer>
            <ListOne>Choose product and click on the “Enroll Now” button.</ListOne>
            <ListTwo>Work with your health care provider to download and complete the enrollment form for your medication, OR complete the electronic patient enrollment form if available.</ListTwo>
            <ListThree>Print and fax the completed downloadable form, OR submit the electronic form online if available.</ListThree>
            <ListFour>A program representative will contact you and your health care provider’s office.</ListFour>
        </ListContainer>
    </ListWrapper>
)

const ListWrapper = styled.div`
    margin: 1em 0;

    h2 {
        color: var(--dark-blue);
        margin-bottom: 1rem;
    }
`

const ListContainer = styled.div`
    font-family: Montserrat;
    font-size: 16px;
    line-height: 20px;

    @media (max-width: 415px) {
        font-size: 12px;
        line-height: 14px;
    }
    
    div {
        display: flex;
        align-items: center;
        padding: .75rem 3rem .75rem 4rem;
        height: 55px;
        margin-bottom: 6px;
        position: relative;

        @media (max-width: 415px) {
            padding: 0.75rem 0.2rem 0.75rem 4.2rem;
        }
    }
`

const ListOne = styled.div`
    background-color: var(--pink-highlight);

    &:before {
        content: '';
        background-image: url(${List1});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 55px;
        position: absolute;
        top: 0;
        left: -4px;
    }
`

const ListTwo = styled.div`
    background-color: var(--dark-blue-highlight);

    @media (max-width: 415px) {
        height: 80px !important;
    }

    &:before {
        content: '';
        background-image: url(${List2});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 55px;
        position: absolute;
        left: -4px;

        @media (max-width: 415px) {
            
        }
    }
`

const ListThree = styled.div`
    background-color: var(--light-blue-highlight);
    &:before {
        content: '';
        background-image: url(${List3});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 55px;
        position: absolute;
        top: 0;
        left: -4px;
    }
`

const ListFour = styled.div`
    background-color: var(--green-highlight);
    &:before {
        content: '';
        background-image: url(${List4});
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 55px;
        position: absolute;
        top: 0;
        left: -4px;
    }
`

export default ListCallout